.socialIcon{
	margin: 0px 10px 0px 0px;
	background-color: #6366f1;
	border-radius: 15px;
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center; 
}

.socialIcon:hover{
	background-color: #8587fa;
	cursor: pointer;
}