.headerFontHeader {
    font-family: Proxima-Nova-Black;
    /* color: #0004; */
    width: 80%;
    max-width: 700px;
    /* padding-top: 100px; */
    margin: 0 auto;
    color: #fff;
    text-shadow: 0 1px 4px rgba(0,0,0,0.1);
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    /* margin-top: 100px; */
    margin-bottom: 15px;
    font-size: 40px;  
    line-height: 48px;
    font-weight: 100;
    letter-spacing: 1px;
    /* padding: 0; */
    text-align: center;
    justify-content: center;
   

}
.headerFontSubtext{
    font-family: Proxima-Nova;
    /* color: #0004; */
    /* width: 80%;
    max-width: 700px;
   
     */
     margin: 0 auto;
    color: #fff;
    text-shadow: 0 1px 4px rgba(0,0,0,0.1);
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    padding-top: .25em;
    padding-bottom: 80px;
    max-width: 1018px;
    width: 60%;
}
.FontCard{
    font-family: Proxima-Nova;
    /* color: #0004; */
    /* width: 80%;
    max-width: 700px;
   
     */
     margin: 0 auto;
    color: rgb(0, 0, 0);
    text-shadow: 0 1px 4px rgba(0,0,0,0.1);
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    padding-top: .25em;
    padding-bottom: 80px;
    max-width: 1018px;
    width: 60%;
}
.textContainer {
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}
.profileScreen {
    /* height: 100vw; */
    /* flex:1; */
    /* margin-top: -100px; */
    /* height: 50vh; */
    background-color:#5F63BA;

 
}
.titleText{
    /* font-family: Proxima-Nova-Black; */
    /* font-weight: 550; */
}

.aboutImage{
    /* filter: grayscale(100%); */
}
.aboutLogo {
    font-size: 88px;  
    font-family:Pacifico-Regular;
    letter-spacing: 10;
    color: white;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
}


.nameText {
    font-size: 24px;  
    font-family:Pacifico-Regular;
    letter-spacing: 10;
    /* color: white;
    text-align: center; */
    margin-top: 100px;
    margin-bottom: 100px;
    color:#5F63BA;
    flex-wrap: nowrap;
}

.Section2Header{
     font-family: Proxima-Condensed;
    /* color: #0004; */
    width: 80%;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 30px;
    color:#222;
    /* text-shadow: 0 1px 4px rgba(0,0,0,0.1); */
    /* display: inline-block; */
    vertical-align: middle;
    /* position: relative; */
    /* margin-top: 100px; */
    margin-bottom: 15px;
    font-size: 36px;  
    line-height: 48px;
    font-weight: bold;
    /* font-weight: 100; */
    padding: 0;
    text-align: center;
    justify-content: center;
   

}

html,body, #root {
    background-color: #282b30;
  }