@media (max-width: 500px) {
    .campaign-card-right {
      display: none !important;
    }
    .campaign-card-left {
      text-align: center !important;
    }
  }

  .clipboard {
    background-color: #ffffff;
    border-radius: 4px;
    border: none;
    color: white;
    padding: 5px 10px 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
  
  .clipboard:hover {
    background-color: #efefef;
  }
  